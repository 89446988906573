export const Translations = {
	"en": {
		test: {
			"label": {
				yes: "Yes",
				no: "No",
				null: null
			},
			"action": {
				yes: "@test.label.yes",
				no: "@test.label.no"
			},
			"view:demo": {
				yes: "Yesss",
				no: "@test.action.no"
			}
		}
	},
	"en-US": {
		test: {
			"view:demo": {
				yes: "Si"
			}
		}
	},
	"de": {
		test: {
			label: {
				yes: "Ja"
			},
			action: {
				yes: "@test.label.yes",
				no: "@test.label.no"
			}
		}
	}
};
