
const text =
	"Mit dem Login in comunas.fm akzeptiere ich die folgenden Bedingungen bzw Einschränkungen für den Pilotbetrieb:\n\n" +
	"**comunas GmbH** und/oder **zeitwert GmbH** übernehmen **keine Verantwortung** für\n\n" +
	"1. **einen Datenverlust** (wir werden jedoch die Daten periodisch manuell sichern, so dass ein allfälliger Datenverlust beschränkt sein wird)\n" +
	"2. **Fehler in den Bewertungen**. Wir empfehlen die Bewertungen zuerst durch comunas GmbH überprüfen zu lassen, bevor sie als Grundlage für eine Budgetierung herangezogen werden.\n\n" +
	"**best effort SLA**\n" +
	"Allfällige Fehler in der Software oder Probleme mit deren Bedienung werden nach bestem Bemühen behoben, es gibt aber keine verbindliche Zusagen für Antwortzeiten\n";

export default text;
